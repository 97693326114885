.buybox-mobile {
  .cart-button {
    background: #10C11C;
  }
}
.buybox {
  .buybox-header {
    background: #FFF200;

    .price-vat {
      color: #626262;
    }
  }

  .cart-button {
    background: #10C11C;
  }
}

@include breakpoint(large) {

}