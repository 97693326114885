.services {
  margin-bottom: rem-calc(15);

  &.small-horizontal,
  &.horizontal {
    a {
      display: block;
      margin-bottom: rem-calc(15);

      &:after {
        content: '';
      }
    }

    .service {
      display: block;
    }
  }

  a {
    &:after {
      content: '\2014';
    }
  }

  .service {
    color: #22a62b;
    margin-right: rem-calc(15);

    &:before {
      @extend %icomoon-icon;
      content: "\e923";
      padding-right: rem-calc(5);
    }
  }

  @include breakpoint(medium) {
    .service {
      display: inline-block;
    }

    &.small-horizontal {
      a {
        display: inline-block;

        &:after {
          content: '\2014';
        }
      }

      .service {
        display: inline-block;
      }
    }
  }
}

.input-group.amount {
  border: 1px solid #ccc;
  margin: 0;

  button,
  input {
    margin: 0;
    border: 0;
    text-align: center;
  }

  input {
    padding: 0.5rem;
    min-width: rem-calc(40);
  }
}

div[data-type='validator-error'] {
  font-size: 11px;
  position: absolute;
  z-index: 10;
  margin-top: 9px;
  margin-left: 337px;
  color: #ffffff;
  background: #c83535;
  box-shadow: 0 6px 2px -2px rgba(211, 77, 77, 0.47);
  padding: 4px 10px;
  border-radius: 1px;
  pointer-events: none;
  display: none;
}
div[data-type='validator-error']:before {
  content: '';
  width: 0;
  height: 0;
  top: 6px;
  position: absolute;
  left: -5px;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid #c83535;
}

