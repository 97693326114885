.is-ctl-checkout {


  .button {
    &.download {
      background-color: white;
      color: #253d98;
      border: 1px solid #253d98;
    }
  }

  .displaybody{
    display: inline-block;
    width: 100%;
    label{
      font-size: 2rem;
      &:before{
        vertical-align: middle;
        display: none;
      }
    }
  }
  @media screen and (max-width: 679px){
    .displaybody{
      label{
        width: 100%;
        &:before{
          display: inline-block;
        }
      }
    }
  }
  .succesblock{
    background:  rgb(244,244,244); padding: 2rem;    max-width: 450px;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
  }

  .news{
    border: 2px solid gray;
    &.greennews{
      border: 2px solid green;
    }
    .news-header{
      padding: 1.25rem;
      text-align: left;
      font-size: 1.5em;
      padding-bottom: 0.5rem;
      div{

        border-bottom: 1px solid #d6d5d5;
        padding-bottom: 1rem;
      }

    }
    .news-subheader{
      padding: 0.5rem 1.25rem 0.5rem 1.25rem;
      text-align: left;
    }
    .locationsepator{
      width: 65px;
      margin: .25rem auto .25rem 1.25rem;
    }
    .news-body{
      padding: 0.25rem 1.25rem 1.25rem 1.25rem;
    }
    .comments{
      color: grey;
      &.border {
        border-right: 1px solid rgba(10, 10, 10, 0.25);
      }
    }

    .new{
      font-weight: bold;
      color: green;
      text-decoration: underline blue;
      padding-left: 1em ;
    }

  }
  &.is-act-review-order {
    .billing-address,
    .payment-delivery,
    .shipping-address {
      .button {
        margin-top: rem-calc(10);
      }
    }

    .reveal {
      .ajax-container {
        hr {
          margin: 1rem auto;
        }

        .change-shipping-address {
          .list-item {
            padding: rem-calc(5) 0;

            &:hover {
              background: #eee;
            }

            &:last-of-type {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  &.is-act-success {
    .address {
      margin-top: rem-calc(10);
    }
  }

  .grid-container {
    .checkout-control {
      margin-top: rem-calc(20);
    }
  }

  .individual_message{
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-bottom: solid 1px #aeacd0;
  }
  
  .delivery-method {
    border: 1px solid #D6D5D5;
    padding: 1.5rem;

    hr {
        border-color: #D6D5D5;
    }

    .grid-dm {
        border: 2px solid #AEACD0;
        box-sizing: border-box;
        margin: 5px auto;

        .radio-dm {
            border-right: 1px solid #AEACD0;
            display: table;
            text-align: center;
            height: 100%;

            input {
                display: inline-block;
                vertical-align: middle;
                margin: unset;
                padding: unset;
                align-items: center;
            }

            label {
                vertical-align: middle;
                display: table-cell;
            }
        }

        .content-dm {
            padding: 0.5rem;
        }
    }
}

    .reveal {
      .branch-list {
        height: 50vh;
        margin-bottom: 1rem;
        overflow-y: scroll;
          
        &::-webkit-scrollbar {
            width: 5px;               /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: #EBEBF3;        /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
            background-color: #253D98;    /* color of the scroll thumb */
            border-radius: 6px;       /* roundness of the scroll thumb */
            border: 2px solid #253D98;  /* creates padding around scroll thumb */
        }

        .branch-select {
            border: 1px solid #293578;
            padding: 1rem;
            margin: 1rem;
            cursor: pointer;

            &.selected {
                background: #EBEBF3;
            }
            .check-icon {
                text-align: right;
                color: #293578;
            }
        }
      }
    }  
  
  @media screen and (min-width: 50px){
    .ship-method{
      margin-top: 2rem;
    }

    .blocks{
        border: 1px solid #d6d5d5;
        margin-top: 1em;
        padding: 15px;
      .block-cell{
        &.subcontent_title{
          font-size:  2rem;
        }
        border-bottom: 1px solid #d6d5d5;
        //padding-bottom: 1em;
        //padding-top: 1em;
        label{
          display: inline-block;
        }
      }/*
      .indicator {
        position: relative;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        //box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
        overflow: hidden;
      }*/
    }


    /*input[type='radio'] {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      outline: none;
      border: 3px solid gray;
    }

    input[type='radio']:before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
    }

    input[type="radio"]:checked:before {
      background: green;

    }

    input[type="radio"]:checked {
      border-color:green;
    }
*/
    .greenlabel{
      color: rgb(18,160,28);
      font-weight: bold;
    }
    .wrapper {
      margin: 8px 0;
      label{
        font-size: 1rem;
      }
      .method{
        color: black;
        font-weight: bold;
      }
      .methoddetail{
        color: grey;
      }
      img{
        max-height: 40px;
      }
    }

    .state {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 1e-5;
      pointer-events: none;
    }

    .label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: #394a56;
      background: none;
    }


    .indicator {
      display: inline-block;
      position: relative;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      border: solid 3px #aeacd0;
      overflow: hidden;
      vertical-align: middle;
    }

    .indicator::before,
    .indicator::after {
      content: '';
      position: absolute;
      top: 25%;
      left: 25%;
      height: 50%;
      width: 50%;
      border-radius: 50%;
    }

   /* .indicator::before {
      box-shadow:
              -4px -2px 4px 0px #d1d9e6,
              4px 2px 8px 0px #fff;
    }

    .indicator::after {
      background-color: #ecf0f3;
      box-shadow:
              -4px -2px 4px 0px #fff,
              4px 2px 8px 0px #d1d9e6;
      transform: scale3d(1, 1, 1);
      transition: opacity .25s ease-in-out, transform .25s ease-in-out;
    }*/

    .state:checked ~ .label .indicator::after {
      /*transform: scale3d(.975, .975, 1) translate3d(0, 10%, 0);
      opacity: 0;*/
      background-color: black;
    }

    /*.state:focus ~ .label .text {
      transform: translate3d(8px, 0, 0);
      opacity: 1;
    }*/

   /* .label:hover .text {
      opacity: 1;
    }*/
  .articleName{

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: grey;
  }

    .cartitems{
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .totalprice{
      background-color: rgb(235,236,244);
    }
    .totalpricenumber{
      color: rgb(121,121,123);
    }

  }

}

@include breakpoint(medium) {
}