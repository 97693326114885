.main-header {
  .header-navigation {
    .navigation-entry.entry-search {
      .search-results {
        color: $black;
        display: none;
        position: fixed;
        background: $white;
        border: 1px solid #d6d5d5;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 26;
        height: 100%;
        overflow-y: auto;

        &.is-active {
          display: block;
        }

        .cell {
          &.mobile {
            background: #F4F3F3;
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(10);
          }
        }

        .mobile-search {
          display: flex;
          flex-flow: row nowrap;

          .search-form {
            flex-grow: 1;
            padding-right: 1rem;

            input {
              border: 1px solid #AEACD0;
            }
          }

          button.cancel {
            color: #253D98;
            font-size: rem-calc(14);
            line-height: rem-calc(18);
            font-weight: 300;
          }
        }

        p {
          margin: 0;
          padding-top: 1rem;
          padding-bottom: 1rem;
          color: $black;
        }

        .result-header {
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;

          .title {
            font-weight: 600;
          }
        }

        .price, .stock {
          display: inline-block;
        }

        .price {
          font-weight: 600;
          padding-right: 1rem;
        }

        .stock {
          color: red;

          &.on-stock {
            color: #12a01c;
          }

          [class^="icon-"], [class*=" icon-"] {
            vertical-align: text-bottom;
          }
        }

        .preview-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        .content-wrapper {
          display: block;
        }

        .preview-image {
          padding-right: 1rem;
          flex-shrink: 0;

          img {
            width: 60px;
            height: auto;
          }
        }

        .preview-content {
          flex-grow: 1;
          overflow: hidden;
          display: -webkit-box;
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          padding-right: 1rem;
        }

        .item-form {
          flex-shrink: 0;
          padding-top: rem-calc(20);

          .button {
            margin: 0;
          }
        }

        .meta {
          padding-top: rem-calc(8);
          padding-bottom: rem-calc(8);
        }

        ul.result-list {
          margin: 0;

          li.result-item {
            border-bottom: 1px solid #d6d5d5;
            display: block;
            padding: rem-calc(10) 1.875rem;
            margin-right: -1.875rem;
            margin-left: -1.875rem;

            &:first-of-type {
              border-top: 1px solid #d6d5d5;
            }

            &:last-of-type {
              border: none;
            }
          }
        }

        .meta {
          display: flex;
          background: #f4f3f3;

          .count {
            margin-left: auto;
          }
        }
      }
    }
  }
}

.is-ctl-search {
  &.is-act-search {
    #main-content {
      .search-results {
        .result-item {
          margin-bottom: rem-calc(20);
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .main-header {
    .header-navigation {
      .navigation-entry.entry-search {
        .search-results {
          ul.result-list {
            li.result-item {
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              margin-right: -1.25rem;
              margin-left: -1.25rem;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(large) {
  .main-header {
    .header-navigation {
      .navigation-entry.entry-search {
        .search-results {
          position: absolute;
          width: 100%;
          top: calc(100% - #{rem-calc(10)});
          left: unset;
          right: unset;
          height: auto;
          overflow-y: unset;
          z-index: 24;

          &.is-active {
            display: block;
          }

          &:after, &:before {
            bottom: 100%;
            left: 20px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 12px;
            margin-left: -12px;
          }

          &:before {
            border-color: rgba(235, 236, 244, 0);
            border-bottom-color: #d6d5d5;
            border-width: 13px;
            margin-left: -13px;
          }

          .result-header {
            display: flex;
            flex-flow: row nowrap;
            padding-top: 0;

            .information {
              text-align: right;
              flex-grow: 1;
              margin-right: rem-calc(230);
            }
          }

          .preview-wrapper,
          .content-wrapper {
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 1;
            align-items: center;
          }

          .cell {
            padding-top: 1rem;

            &.categories {
              border-right: 1px solid #d6d5d5;
            }

            &.articles {
              padding-left: 0;
              padding-right: 0;

              .result-header {
                padding-left: 0.9375rem;
                padding-right: 0.9375rem;
                padding-bottom: 0;
              }
            }

            &.meta {
              border-top: 1px solid #d6d5d5;
              padding-top: 0.5rem;
            }
          }

          .stock, .price {
            display: block;
          }

          .price {
            padding: 0;
          }

          .preview-information {
            white-space: nowrap;
            text-align: right;
          }

          .item-form {
            padding-left: 1rem;
            padding-top: 0;
            flex-basis: rem-calc(230);
          }

          .preview-image {
            img {
              width: 45px;
              height: auto;
            }
          }

          ul.result-list {
            li.result-item {
              display: flex;
              align-items: center;
              flex-flow: row nowrap;
              padding: 0.9375rem;
              margin: 0;

              &:first-of-type {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}