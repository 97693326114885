.is-ctl-shopping-list.is-act-detail {
  .shopping-list-wrapper {
    margin: 0;
    padding-bottom: rem-calc(20);
  }

  label,
  .shopping-list-label,
  .shopping-list-header {
    font-weight: bold;
  }

  .shopping-list-header {
    margin-bottom: rem-calc(20);
  }

  .shopping-list {
    border-top: 1px solid #ccc;
    list-style: none;
    padding-top: rem-calc(20);
    margin: 0;

    .shopping-list-item {
      border-bottom: 1px solid #ccc;
      list-style: none;
      margin-bottom: rem-calc(20);
      padding-bottom: rem-calc(20);
    }

    .piece-units {
      margin-bottom: rem-calc(20);
      margin-top: rem-calc(20);

      .values {
        padding-right: rem-calc(10);
        border-right: 1px solid #ccc;
      }

      .unit {
        padding-left: rem-calc(10);
      }
    }

    .button {
      margin: 0;
    }
  }

  .shopping-list-footer {
    .cell:not(.total-price) {
      display: none;
    }
  }
}

@include breakpoint(medium) {
  .is-ctl-shopping-list.is-act-detail {
    .piece-units {
      margin-top: 0;
    }
  }
}

@include breakpoint(large) {
  .is-ctl-shopping-list.is-act-detail {
    .piece-units {
      margin-bottom: 0;
    }
  }
}