$border-color: #C8C8DF;
$heading-background: #253D98;
$menu-background: #EBECF4;

.account {
  .account-label {
    color: $dark-gray;
  }

  .add-button {
    background: #10C11C;
    color: $white;
    border: 2px solid #12A01C;
    font-weight: bold;
  }

  .settings-heading {
    padding: 1.4rem;
    background: #F4F3F3;
    border-bottom: 1px solid #d6d5d5;
    font-weight: bold;
  }

  .settings-content {
    padding: 1.4rem;
  }

  .settings-content + .settings-content {
    border-top: 1px solid #d6d5d5;
  }

  .company-container {
    .company-overview,
    .company-details {
      border: 2px solid #d6d5d5;
    }

    .company-details {
      border-top: none;

      .details-heading {
        padding: 1.4rem;
        background: #F4F3F3;
        border-bottom: 1px solid #d6d5d5;
        font-weight: bold;
      }

      .details-content {
        padding: 1.4rem;
      }

      .details-content + .details-content {
        border-top: 1px solid #d6d5d5;
      }
    }

    .company-overview + .company-details {
      border-top: 2px solid #d6d5d5;
    }

    .company-overview {
      border-bottom: none;
      padding: 1.4rem;

      .company-name {
        font-size: 1.2rem;
      }
    }

    .company-limit {
      border: 2px solid #aeacd0;
      padding: 1rem 1.4rem;
      background: #EBECF4;

      .value {
        font-size: 1.2rem;
      }
    }

    & + & {
      margin-top: 1rem;
    }
  }

  .company-container + .company-container {
    margin-top: 1rem;
  }

  .account-sidebar {
    height: 100%;
    background: $menu-background;

    .heading {
      background: $heading-background;
      color: $white;
      padding: rem-calc(15) rem-calc(35) rem-calc(15) rem-calc(24);
      position: relative;
      cursor: pointer;

      &:after {
        position: absolute;
        right: rem-calc(24);
        top: 50%;
        transform: translateY(-50%);
        @include icomoon-icon;
        content: "\e908";
        color: $white;
      }

      &.is-active {
        &:after {
          content: "\e901";
        }
      }
    }

    .menu:first-of-type {
      background: $menu-background;
      padding: rem-calc(10) 0;

      li {
        background: transparent;

        a {
          padding: 0 rem-calc(24);

          &.current {
            border-left: 4px solid $border-color;
            background: $white;
            margin-top: -1px;

            .text {
              border: none;
            }
          }

          .text {
            color: $black;
            padding: rem-calc(15) 0;
            border-bottom: 1px solid $border-color;
          }
        }

        &:first-of-type {
          a {
            .text {
              border-top: 1px solid $border-color;
            }

            &.current {
              .text {
                border: none;
              }
            }
          }

          ul {
            li {
              a {
                .text {
                  border-top: none;
                }
              }
            }
          }
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            a {
              padding-left: rem-calc(24);
              padding-right: rem-calc(24);

              .text {
                padding-left: rem-calc(24);
              }
            }

            &:first-of-type {
              a {
                .text {
                  border-top: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.shipping-address {
    .addressReference {
        height: 150px;
    }
}
