.cookie-notice {
  position: fixed;
  bottom: 0;
  z-index: 28;
  background: #3f4c58;
  width: 100%;
  color: $white;
  padding: rem-calc(20) 0;
  opacity: 0.9;

  .button {
    margin: 0;
  }

  &.is-closed {
    display: none;
  }
}
