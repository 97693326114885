.is-ctl-cart,
.is-ctl-checkout {
  .progress-bar {
    .steps {
      text-align: left;
      overflow: hidden;
      margin: 0 0 rem-calc(20);
    }

    .step {
      text-decoration: none;
      outline: none;
      display: block;
      float: left;
      font-size: rem-calc(18);
      line-height: rem-calc(50);
      padding: 0 rem-calc(20) 0 rem-calc(40);
      position: relative;
      background: #fff;
      color: #626262;
      width: 20%;
      border: 1px solid #D5D5D5;

      .text {
        @include ellipsis;
      }

      &:first-child {
        padding-left: rem-calc(20);

        &:before {
          left: rem-calc(14);
        }
      }

      &:last-child {
        padding-right: rem-calc(20);

        &:after {
          content: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: rem-calc(-24);
        width: rem-calc(50);
        height: rem-calc(50);
        transform: scale(0.707) rotate(45deg);
        z-index: 1;
        background: #fff;
        box-shadow: 3px -3px 0 1px #d5d5d5;
      }

      &.is-active {
        background: #EBECF4;
        border: 1px solid #aeacd0;

        &::after {
          background: #EBECF4;
          box-shadow: 3px -3px 0 1px #aeacd0;
        }
      }

      &.current {
        color: $black;
        background: $white;
        font-weight: 600;
        border: 1px solid #aeacd0;

        &::before {
          color: #e91e63;
        }

        &::after {
          background: $white;
          box-shadow: 3px -3px 0 2px #aeacd0;
        }
      }

      &.previous-current {
        &::after {
          box-shadow: 3px -3px 0 2px #aeacd0;
        }
      }
    }
  }
}