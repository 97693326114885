.button {
  position: relative;
}

img[src=""] {
  display: none;
}

.align-baseline {
  align-items: baseline;
}

.cursor-pointer {
  cursor: pointer;
}