$background: #253D98;
$color: #FFF200;
$border: #5164ad;

.main-header {
  background: $background;
  color: $color;
  border-bottom: 1px solid #AEACD0;
  position: relative;

  //a {
  //}

  .entry-link {
    margin: 0;

    .entry-text {
      margin-left: rem-calc(5);
      position: relative;
      vertical-align: middle;
      display: inline-block;
      white-space: nowrap;
    }

    [class^="icon-"], [class*=" icon-"] {
      color: #AEACD0;
      font-size: rem-calc(24);
      vertical-align: middle;
    }
  }

  .navigation-entry {
    display: inline-block;
  }

  .top-bar {
    display: none;
    padding: 0;
    background: #eee;

    & > .grid-container {
      padding: 0;
    }

    .dropdown.menu > li > a {
      background: #eee;
      color: #0a0a0a;
    }
  }

  .mobile-navigation {
    background: $white;

    .navigation-entry {
      padding-top: rem-calc(12);
      padding-bottom: rem-calc(12);
    }

    .entry-link {
      font-size: rem-calc(14);
      font-weight: 300;
      line-height: rem-calc(18);
      color: $background;
    }
  }

  .header-navigation {
    padding: rem-calc(15 0 5 0);
    display: flex;
    flex-flow: row nowrap;
    min-height: rem-calc(80);

    .logo {
      img {
        width: rem-calc(50);
        height: 100%;
      }

      //  display: flex;
      //  align-items: center;
      //
      //  a {
      //    padding: 0.6rem 0;
      //
      //    img {
      //      max-height: 60px;
      //    }
      //  }
      //
      //  .logo-text {
      //    margin-left: 1.5rem;
      //    padding: 1rem 0;
      //    color: $color;
      //  }
    }

    .navigation-entry {
      &.entry-search {
        .search-form {
          display: none;
          position: absolute;
          left: 0;
          width: 100%;
          bottom: rem-calc(10);

          .button {
            background: $color;
            color: $black;
          }

          input {
            padding-left: 2.5rem;
            border: none;
            box-shadow: none;
          }

          .input-group {
            margin: 0;
          }

          .icon-search {
            pointer-events: none;
            position: absolute;
            font-size: 1rem;
            padding: 0.7rem;
            color: #cecece;
          }
        }

        .search-results {
          .search-form {
            display: block;
            position: relative;
            left: unset;
            bottom: unset;
          }
        }
      }
    }

    .navigation-list {
      justify-content: flex-end;
      margin: 0;
      flex-grow: 1;

      .navigation-entry {
        position: relative;
        padding-right: rem-calc(15);
        vertical-align: top;

        &:last-of-type {
          padding: 0;
        }

        & > .entry-text {
          font-weight: 600;
          font-size: rem-calc(9);
          line-height: rem-calc(18);
          text-align: center;
          color: #AEACD0;
          @include ellipsis;
          max-width: rem-calc(42);
        }

        .badge {
          position: absolute;
          right: -0.7rem;
          top: -0.5rem;
          border-radius: 0;
          background: #E64552;

          [class^="icon-"], [class*=" icon-"] {
            font-size: 0.4rem;
            color: $white;
          }
        }

        &.entry-account .badge {
          background: #AEACD0;
          border-radius: 50%;

          [class^="icon-"], [class*=" icon-"] {
            color: #253D98;
          }
        }

        .entry-link {
          border: 1px solid #AEACD0;
          background-color: transparent;
          padding: rem-calc(8);

          .entry-text {
            color: $color;
            font-weight: 600;
            text-align: left;
            max-width: rem-calc(120);

            .white {
              color: $white;
              font-weight: normal;
            }
          }
        }

        &.entry-menu {
          .entry-link {
            background: $color;

            [class^="icon-"], [class*=" icon-"] {
              color: #253D98;
            }
          }
        }

        .navigation-layer {
          position: absolute;
          z-index: 23;
          background: $white;
          color: $background;
          border: 1px solid #EBECF4;
          transition: all 0.5s;
          box-shadow: 0 10px 25px -15px #000;
          opacity: 0;
          visibility: hidden;
          width: calc(100% + #{rem-calc(30)});
          text-align: left;
          left: rem-calc(5);
          padding: 0 rem-calc(10);

          &:after, &:before {
            bottom: 100%;
            left: rem-calc(20);
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 12px;
            margin-left: -12px;
          }

          &:before {
            border-color: rgba(235, 236, 244, 0);
            border-bottom-color: #EBECF4;
            border-width: 13px;
            margin-left: -13px;
          }

          ul {
            li {
              border-top: 1px solid #EBECF4;

              a {
                display: block;
                padding: rem-calc(5) rem-calc(10);
                color: $background;
                margin: rem-calc(5) rem-calc(5);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                  background: #ebecf4;
                }
              }

              &:first-of-type {
                border-top: none;
              }

              &:last-of-type {
                &.grey {
                  border-top: none;
                  background: #F4F3F3;
                  font-weight: 600;
                  margin-right: rem-calc(-10);
                  margin-left: rem-calc(-10);
                  padding: 0 rem-calc(10);
                  margin-bottom: 0;

                  a {
                    margin-bottom: 0;
                    margin-top: 0;
                    padding: rem-calc(10);

                    &:hover {
                      background: none;
                    }
                  }
                }
              }
            }
          }
        }

        &.entry-cart {
          position: static;
          
          .entry-link {
            &.is-active {
              background: #FFF200;

              [class^="icon-"], [class*=" icon-"] {
                color: #253D98;
              }
            }
          }

          .icon-cart {
            position: relative;
          }
        }
      }
    }
  }

  .main-navigation {
    padding: 0;
    position: relative;
    border-top: 1px solid $border;

    .navigation-list {
      position: relative;
      width: 100%;
      margin: 0;
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      .navigation-entry {
        display: block;
        position: relative;

        &.hovered {
          &:after,
          &:before {
            bottom: 0;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:before {
            border-color: rgba(235, 236, 244, 0);
            border-bottom-color: #EBECF4;
            border-width: 13px;
            margin-left: -13px;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 12px;
            margin-left: -12px;
          }
        }

        &.is-active .navigation-link {
          color: #f5e912;
        }

        .navigation-link {
          color: $white;
          display: block;
          padding: 0.8rem 0;
          font-size: rem-calc(20);
          font-weight: 600;

          .navigation-text {
            padding: 0 2rem;
            border-right: 1px solid $border;
          }

          &:hover {
            //background: darken($background, 2%);
          }
        }

        &:first-of-type {
          .navigation-link {
            .navigation-text {
              padding-left: 0;
            }
          }
        }

        &:last-of-type {
          .navigation-link {
            .navigation-text {
              border: none;
            }
          }
        }

        &.is-active {
          //background: $primary-color;

          .navigation-link {
            //color: $white;

            &:hover {
              background: transparent;
            }
          }
        }

        // hide scrollbar on firefox
        .is-firefox & {
          margin-bottom: rem-calc(5);
        }
      }
    }

    .mega-menu-container {
      display: none;
    }
  }

  .is-firefox & .main-navigation + .scrollbar-overlay {
    background: #eee;
    position: absolute;
    width: 100%;
    height: rem-calc(20);
    bottom: rem-calc(-21);
  }

  .shop-navigation {
    position: relative;
  }
}

@include breakpoint(medium) {
  .main-header {
    .header-navigation {
      .navigation-list {
        .navigation-entry {
          &:not(.entry-search) {
            padding-right: rem-calc(20);
          }
        }
      }
    }
  }
}

@include breakpoint(large) {
  .search-container-opened,
  .search-container-focus {
    .main-header {
      .header-navigation {
        .logo {
          display: none;
        }
      }
    }
  }

  .main-header {
    &.search-form-is-active {
      padding: 0;
    }

    .top-bar {
      display: block;
    }

    .main-navigation {
      display: block;

      .mega-menu-container {
        position: absolute;
        z-index: 22;
        background: $white;
        width: 100%;
        left: 0;
        box-shadow: 0 10px 25px -15px #000;

        &.is-open {
          display: block;
        }

        .mega-menu {
          display: none;

          &.is-active {
            display: block;
          }

          .menu-header {
            padding: rem-calc(10);
            background: #3f4c58;

            a {
              color: $white;
            }
          }

          .menu-wrapper {
            padding: rem-calc(60 0 35 0);
            min-height: rem-calc(111);

            ul {
              margin: 0;
              padding-right: rem-calc(15);

              li {
                margin-bottom: rem-calc(10);
                padding-left: rem-calc(12);
                position: relative;

                &.hovered {
                  &:before {
                    position: absolute;
                    content: '> ';
                    left: 0;
                    color: get-color(primary);
                  }
                }

                a {
                  position: relative;
                  color: get-color(primary);
                }

                &:last-of-type {
                  margin: 0;
                }
              }
            }

            .menu-image {
              img {
                width: 100%;
              }
            }

            .menu-element {
              .grid-x {
                min-height: rem-calc(111);
              }
            }
          }
        }
      }
    }

    .header-navigation {
      padding-bottom: rem-calc(15);
      position: relative;

      .logo {
        //transition: all 0.5s;
        margin-right: 3%;

        img {
          width: rem-calc(200);
        }
      }

      .navigation-list {
        display: flex;
        align-items: center;
        margin-left: 3%;
        flex-grow: 0;

        .navigation-entry {
          &:not(.entry-search) {
            padding-right: rem-calc(5);

            .entry-link {
              border-color: transparent;
              background-color: transparent;
              display: flex;
              flex-flow: row;
              align-items: center;

              &:hover,
              &.layer-open {
                background: #192e7c;
              }
            }
          }

          &.entry-menu {
            display: none;
          }

          .navigation-layer {
            &.is-open {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      .navigation-entry.entry-search {
        flex-grow: 1;

        .entry-link {
          display: none;
        }

        .search-form {
          display: block;
          position: relative;
          bottom: unset;
          margin: 0;

          .input-group {
            padding: 0.2rem;
            background: $white;

            .button {
              border: 2px solid #E6DE4E;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
