::selection {
  background: #253D98;
  color: $white;
}

header.main-header,
footer.main-footer {
  *::selection {
    color: #253D98;
    background: $white;
  }
}

header.main-header {
  .search-results,
  .search-form {
    *::selection {
      background: #253D98;
      color: $white;
    }
  }

}

.ellipsis {
  @include ellipsis;
}