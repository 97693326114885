.ring-loader {
  display: inline-block;
  position: relative;
  width: rem-calc(64);
  height: rem-calc(64);

  .button:not(.hollow) & {
    width: rem-calc(20);
    height: rem-calc(20);
    position: absolute;
    display: block;
    right: 0;

    div {
      border-color: $white transparent transparent transparent;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  .button.hollow & {
    width: 2rem;
    height: 2rem;
    position: absolute;
    display: block;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    div {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0.4rem;
      border-width: 2px;
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: rem-calc(51);
    height: rem-calc(51);
    margin: rem-calc(6);
    border: 6px solid $primary-color;
    border-radius: 50%;
    animation: ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-color transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes ring-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


body {
  & > .loader-overlay {
    z-index: 9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    background: rgba(10, 10, 10, 0.6);
    visibility: hidden;
    opacity: 0;

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  & > .ring-loader {
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.off-canvas {
  .ring-loader {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > .loader-overlay {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    background: rgba(10, 10, 10, 0.2);
    visibility: hidden;
    opacity: 0;

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }
  }
}