$light-grey: #f4f3f3;
$grey: #d6d5d5;
$font: #757575;
$active-font: $black;
$border-color: #aeacd0;

.is-ctl-product.is-act-index {
  .sub-group-listing {
    ul {
      list-style: none;
    }

    .hollow.button {
      text-align: left;
      border: 1px solid #d6d5d5;

      img {
        max-height: rem-calc(40);
        margin-right: rem-calc(10);
        flex: 0 0 rem-calc(40);
        object-fit: cover;
      }

      .button-text {
        flex: 1 1 calc(100% - #{rem-calc(50)});
        line-height: rem-calc(40);
        @include ellipsis;
      }

      .amount {
        color: #aaa;
      }
    }
  }

  .filterBG {
    padding-bottom: 20px;
  }

  ul.filtersButton {
    list-style: none;
    margin: 0;
    
    li {
      width: 200px;
      background: #fff200;
      /*padding: 5px 5px;
      text-align: center;*/
      position: relative;
      border: solid 1px #e2d931;
      line-height: 25px;
      display: inline-block;
      vertical-align: top;

      &:hover {
        padding: 0;

        ul.subfilter {
          display: block;
          z-index: 20;
          position: absolute;

          li {
            left: -1px;
            background: #fffcc4;
          }
        }
      }
      
      &.clearFilter {
        background: unset;
        border: unset;

        span {
          text-decoration: underline;
          color: #33499e;
          cursor: pointer;
          width: 170px !important;
        }
      }

      span {
        padding: 2px;
        display: inline-block;
        position: relative;
        
        &.exitF {
          width: 10%;
          font-weight: bold;
          cursor: pointer;
          text-align: center;
        }

        &.typeFilter {
          width: 90%;
          padding-left: 5px;
        }
      }
      
      ul.subfilter {
        display: none;
      }
    }
  }

  .sidebar {
    min-height: 100%;
    background: #EBECF4;
    padding: rem-calc(25);

    .filter {
      margin-bottom: rem-calc(20);

      .filter-item {
        margin-bottom: rem-calc(5);
      }

      .range {
        input {
          display: inline-block;
          width: 30%;

          &:first-of-type {
            margin-right: rem-calc(10)
          }

          &:last-of-type {
            margin-left: rem-calc(10)
          }
        }
      }
    }

    .heading {
      margin-bottom: rem-calc(10);
      position: relative;
      cursor: pointer;

      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @include icomoon-icon;
        content: "\e901";
      }

      &.is-active {
        &:after {
          content: "\e908";
        }
      }
    }

    .collapse-able-content {
      padding-top: rem-calc(20);
      border-top: 1px solid $border-color;
    }
  }

  .listing-options {
    background: $light-grey;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    margin-bottom: rem-calc(25);

    a {
      margin: 0;
      padding: rem-calc(14) rem-calc(25);
      display: inline-block;

      [class^="icon-"], [class*=" icon-"] {
        margin-right: rem-calc(10);
        font-size: rem-calc(20);
        vertical-align: sub;
      }
    }
  }

  .article-listing {
    .article-stock {
      color: red;

      &.in-stock {
        color: #12a01c;
      }
    }

    .article-stock {
      color: red;

      &.in-stock {
        color: #12a01c;
      }
    }

    .article-listing-header {
      padding: rem-calc(13 10);
      background-color: $light-grey;
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
    }

    &.list {
      .article-listing-header,
      input,
      .button {
        margin: 0;
      }

      .article {
        padding: rem-calc(13 10);
        border-bottom: 1px solid $grey;

        .description {
          width: calc(100% - #{rem-calc(52)});
          overflow: hidden;
          display: -webkit-box;
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          line-clamp: 2;
          -webkit-line-clamp: 2;
        }
      }

      .cart-button {
        vertical-align: top;
        border: 1px solid $primary-color;
        border-bottom: none;
      }

      .amount {
        margin: 0;
        vertical-align: top;
      }

      .image {
        width: rem-calc(42);
        margin-right: rem-calc(10);

        img {
          object-fit: cover;
        }
      }
    }

    &.catalogue {
      .article {
        margin-bottom: rem-calc(25);

        .article-header {
          padding-bottom: rem-calc(10);
        }

        .article-image {
          border: 1px solid #ccc;
          padding: rem-calc(20);
        }

        .button {
          margin: 0;
          vertical-align: top;
        }

        .article-body {
          border: 2px solid #aeacd0;
          margin-top: rem-calc(10);
          padding: rem-calc(20)
        }

        .article-controls {
          background: #ebecf4;
          padding: rem-calc(20);
          width: 100%;
          margin: 0;

          .input-group.amount {
            margin-bottom: rem-calc(10);

            @include breakpoint(medium) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.table {
      .article-wrapper {
        margin-bottom: rem-calc(20);

        .article {
          padding: rem-calc(15);
          border: 1px solid #ccc;

          .description {
            margin-top: rem-calc(15);

          }

          .image {
            img {
              border: 1px solid #ccc;
            }
          }

          .label {
            margin-right: rem-calc(20);
          }
        }

        .accordion {
          .accordion-item {
            .accordion-content {
              padding: 0;

              .variants {
                table {
                  .toggle-mobile {
                    span.icon-angle-up {
                      display: none;
                    }

                    &.open {
                      span.icon-angle-up {
                        display: block;
                      }

                      span.icon-angle-down {
                        display: none;
                      }
                    }
                  }

                  tr.foldable {
                    background: $white;
                    display: none;

                    .button,
                    .input-group.amount {
                      margin: 0;
                    }

                    &.active {
                      display: table-row;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .is-ctl-product.is-act-index {
    .product-listing {
      .article-listing {
        &.table {
          .article-wrapper {
            .article {
              .headlines {
                h2,
                h3 {
                  line-height: 1;
                  margin: 0;
                }
              }

              .services {
                margin-top: rem-calc(20);
                margin-bottom: 0;
              }
            }


            .accordion {
              .accordion-item {
                .accordion-title {
                  display: none;
                }

                .accordion-content {
                  display: block !important;

                  .variants {
                    table {
                      .button,
                      .input-group.amount {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media  (min-width: 0px) and (max-width: 680px) {
  .is-ctl-product.is-act-index {
    
    .nav-sidebar {
      background: #EBECF4;
      display: block;
      margin: 0;
      margin-bottom: 20px;
      width: calc(100%);
      overflow: auto;
    }

    .sidebar {
      background: none;
      width: max-content;

      .filter {
        display: inline-block;
        margin-bottom: 0;
        background: #FFFFFF;
        padding: 5px 10px;
        padding-right: 25px;
        margin-right: 10px;
        border: 1px solid #AEACD0;
        box-sizing: border-box;
      }

      .heading {
        margin-bottom: 0;
        width: calc(100% + 20px);
        vertical-align: middle;
        display: block;
      }

      .collapse-able-content {
        margin-top: 5px;
        margin-left: -11px;
        position: absolute;
        background: #EBECF4;
        padding: 5px 15px;
        z-index: 10;
        border: 1px solid #AEACD0;
        box-sizing: border-box;
      }
    }
  }
}