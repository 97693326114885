@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/metropolis/Metropolis-Thin.eot'),
  url('../../fonts/metropolis/Metropolis-Thin.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-Thin.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 100;
  src: url('../../fonts/metropolis/Metropolis-ThinItalic.eot'),
  url('../../fonts/metropolis/Metropolis-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-ThinItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/metropolis/Metropolis-ExtraLight.eot'),
  url('../../fonts/metropolis/Metropolis-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-ExtraLight.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 200;
  src: url('../../fonts/metropolis/Metropolis-ExtraLightItalic.eot'),
  url('../../fonts/metropolis/Metropolis-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-ExtraLightItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/metropolis/Metropolis-Light.eot'),
  url('../../fonts/metropolis/Metropolis-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-Light.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-Light.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 300;
  src: url('../../fonts/metropolis/Metropolis-LightItalic.eot'),
  url('../../fonts/metropolis/Metropolis-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-LightItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/metropolis/Metropolis-Regular.eot'),
  url('../../fonts/metropolis/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-Regular.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 400;
  src: url('../../fonts/metropolis/Metropolis-RegularItalic.eot'),
  url('../../fonts/metropolis/Metropolis-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-RegularItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/metropolis/Metropolis-Medium.eot'),
  url('../../fonts/metropolis/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-Medium.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 500;
  src: url('../../fonts/metropolis/Metropolis-MediumItalic.eot'),
  url('../../fonts/metropolis/Metropolis-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-MediumItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/metropolis/Metropolis-SemiBold.eot'),
  url('../../fonts/metropolis/Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-SemiBold.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 600;
  src: url('../../fonts/metropolis/Metropolis-SemiBoldItalic.eot'),
  url('../../fonts/metropolis/Metropolis-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-SemiBoldItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/metropolis/Metropolis-Bold.eot'),
  url('../../fonts/metropolis/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-Bold.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 700;
  src: url('../../fonts/metropolis/Metropolis-BoldItalic.eot'),
  url('../../fonts/metropolis/Metropolis-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-BoldItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/metropolis/Metropolis-ExtraBold.eot'),
  url('../../fonts/metropolis/Metropolis-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-ExtraBold.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 800;
  src: url('../../fonts/metropolis/Metropolis-ExtraBoldItalic.eot'),
  url('../../fonts/metropolis/Metropolis-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-ExtraBoldItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/metropolis/Metropolis-Black.eot'),
  url('../../fonts/metropolis/Metropolis-Black.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-Black.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-Black.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-style: Italic;
  font-weight: 900;
  src: url('../../fonts/metropolis/Metropolis-BlackItalic.eot'),
  url('../../fonts/metropolis/Metropolis-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/metropolis/Metropolis-BlackItalic.woff2') format('woff2'),
  url('../../fonts/metropolis/Metropolis-BlackItalic.woff') format('woff');
}