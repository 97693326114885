@import 'settings';
@import '~foundation-sites/scss/foundation';
$owl-image-path: "~owl.carousel/src/img/";
@import '~owl.carousel/src/scss/core';
@import '~owl.carousel/src/scss/animate';
@import '~owl.carousel/src/scss/autoheight';
@import '~owl.carousel/src/scss/lazyload';
@import '~owl.carousel/src/scss/video';
@import '~owl.carousel/src/scss/theme.default';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';

@import '~motion-ui/motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;

// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
@include foundation-tabs;
//@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

// @see: https://foundation.zurb.com/building-blocks/blocks/alert-callout-border.html
@import '../../ottcomputer/scss/app/callout-extension';

@import '../../ottcomputer/scss/app/color';
@import '../../ottcomputer/scss/app/mixin';
@import '../../ottcomputer/scss/app/misc';
@import 'app/misc';
@import '../../ottcomputer/scss/app/elements';
@import 'app/elements';
@import 'app/typo';
@import 'app/form';
@import '../../ottcomputer/scss/app/loader';
@import 'app/font';
@import 'app/iconfont';
@import 'app/index';
@import 'app/header';
@import 'app/footer';
@import 'app/search';
@import '../../ottcomputer/scss/app/slider';
@import '../../ottcomputer/scss/app/offcanvas';
@import 'app/offcanvas';
@import 'app/sidebar';
@import 'app/breadcrumbs';
@import 'app/product-listing';
@import '../../ottcomputer/scss/app/product-detail';
@import 'app/product-detail';
@import '../../ottcomputer/scss/app/buybox';
@import 'app/buybox';
@import '../../ottcomputer/scss/app/groups';
@import 'app/cart';
@import '../../ottcomputer/scss/app/shopping-list';
@import 'app/shopping-list';
@import '../../ottcomputer/scss/app/checkout';
@import 'app/checkout-progress-bar';
@import 'app/registration';
@import '../../ottcomputer/scss/app/language-switcher';
@import '../../ottcomputer/scss/app/account';
@import 'app/account';
@import 'app/pagination';
@import '../../ottcomputer/scss/app/cookie-notice';
@import '../../ottcomputer/scss/app/stock-modal';
