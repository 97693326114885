
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $color;
  }
}

.note-label-message{
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid grey ;
}

.timeline{
  width: 100%;
  overflow-x: scroll;
}

.green{
  &.circle{
    border-radius: 50% ; 
  }
}

.account {

  .closecomm{
    cursor: pointer;
  }
  .grid-container {
    max-width: 103.625rem;
  }


  .mobile{
    display: none;
  }
  .desktop{


    display: block;
    h1{
      display: inline-block;
    }
    @media  (min-width: 681px) and (max-width: 1565px) {
      .desktop-button {
        display: none;
      }
      .responsive-col{
        width: calc(16.66667% - 1.875rem);
      }
    }
    @media  (min-width: 681px) and (max-width: 1023px) {
      .responsive-col{
        width: calc(16.66667% - 2.5rem);
      }
    }
    @media  (min-width: 1024px) and (max-width: 1565px) {

      .responsive-col{
        width: calc(16.66667% - 1.875rem);
      }
    }
    @media  (min-width: 1566px)  {
      .responsive-button {
        display: none;
      }
    }
    .usercustomer{
      width: 30%;
      border: none;
      color: #253d98;
      font-size: 2rem;
      padding: 0;
      font-weight: bold;
    }
    .filterclass{
        max-width: 250px;
    }
    .filter{
      padding: 8px;
      @include placeholder-color( #ccc );
    }
    .advancefilter{
      margin: 0;
    }
    .space{
      margin-left: 15px;
    }
    .comments{
      border: 1px solid red;
      color: red;
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .mobiledetails{
    display: none;
  }
  .desktopdetails{
    display: block;
  }
  .right{
    text-align: right;
  }
  .orderStatus{

    &.badge{
      background: rgb(235,236,244);
      color: #253d98;
      border: 1px solid #253d98;
      font-size: 1rem;
      border-radius: 1000px;
    }
    .green{
      color: green;
      background-color: lightgreen;
    }
  }

  @media only screen and (max-width: 800px) {

    .mobiledetails {
      display: block;
      .timelinestatus {
        text-align: center;
      }
      .grey{
        background-color: grey;
      }
      .footer {
          border-top: 1px solid grey;
          padding-top: 15px;
      }

      .statusblock {
        width: 100%;
      }
      .check{
        color: green;
      }

      .historie{
        text-align: center;
        padding-top: 1em;
        padding-bottom: 1em;
      }
      .optionHide{
        display: none;
      }

    }

    .desktopdetails {
      display: none;
    }

  }
  @media only screen and (max-width: 680px) {

    .mobile{
      display: block;

      .number{
        color: blue;
      }
      .card-header{
        margin-top: 5px;
      }
      .order-body{
        margin-top: 10px;
      }

      .card-section{
        &.footer{
          border-top: 1px solid grey;
        }
      }
    }
    .desktop{
      display: none;
    }
  }


  @media  (min-width: 1020px) and (max-width: 1465px) {

    .desktopdetails {
      .order-buttons{
        display: block;
        width: 100%;
        button{
          width: 100%;
        }
      }
      .valuescard{
        overflow-wrap: anywhere;
        line-break: anywhere;
      }
    }
  }

  @media  (min-width: 681px) and (max-width: 800px) {
    .buttonstatus{
      padding: 0.85em 0.40em;
    }
  }
  .orange{
    background-color: orange;
  }
  .statuslabel{
    padding: 15px;
  }

  .card-header{

  }
  &.is-act-order-details {
    .order-header-top {
      margin-bottom: rem-calc(10);
      .order-number-label{
        font-size: 2rem;
      }
      .order-number-number{
        font-size: 2rem;
        font-weight: bold;
      }
    }

    .order-header {
      //border: 1px solid #ccc;
      //padding: 10px;
      background: rgb(244,243,243);
      margin-bottom: rem-calc(10);

      width: 100%;

      .cell {
        margin-bottom: rem-calc(20);
      }

      .order-header-button{
        font-weight: bold;
      }

      @include breakpoint(medium) {
        .cell {
          border-right: 1px solid rgba($black, 0.25);
          padding-left: rem-calc(10);
          margin-bottom: 0;


          &:first-of-type {
            padding: 0;
          }

          &:last-of-type {
            border: none;
          }
        }
      }


    }

    .order-item {
      //padding: 10px;
      //border: 1px solid #ccc;

      & > a {
        color: $body-font-color;
      }

      .description {
        margin-bottom: rem-calc(10);
      }

      .item-content {
        padding-left: rem-calc(10);
      }
    }
  }

    .main-content{
      & > div{
        border: 4px solid #253d98;
        padding: 15px;
      }
      .subcontent_more_details{
        display: none;
      }

      .subcontent{
        border: 1px solid rgb(214,213,213);
        margin-top: 1em;
        .subcontent_title{
          border-bottom: 1px solid rgb(214,213,213);
          padding-bottom: 1em;
          padding-top: 1em;
        }
        .content_title{
          color: #253d98;
        }
        .content_product{
          color: grey;
          strong{
            color: black;
          }
        }


      }

      .steps{
        ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
        li{
          float: left;
          padding: 15px;

          span{
            color: #253d98;
            text-align: center;
            text-decoration: none;

            &.check{
              color: green;
              font-weight: bold;
              &:before{
                margin-right: 10px;
              }
            }
          }
        }
      }

      .subline{
        border-top: 1px solid grey;
      }
      .container{
        padding: 1rem;
      }

      .subcontent_details{
          margin-top: 15px;
          .badge{
            background: rgb(235,236,244);
            color: #253d98;
            border: 1px solid #253d98;
            font-size: 1rem;
            border-radius: 1000px;
          }
        .moreinfo{
          font-weight: bold;
          button{
            font-size: 0.7rem;
            margin: 0;
            background-color: white;
            border:  1px solid grey;
            color: #253d98;
            font-weight: bold;
          }
        }
        &.blueline{
            .moreinfo{
              button{
                background-color: #253d98;
                border:  1px solid #253d98;
                color: white;
              }
            }

        }
      }
      .blueline{
        border-left: 3px solid blue;
      }
      .contain{
        padding-left: 5%;
        padding-right: 5%;
      }
      .subcontent_more_details{
        table{
          margin-bottom: 0;
        }
        tr{
          background-color: white;
          border-bottom: 1px solid #f1f1f1;
          border-top:  1px solid  #f1f1f1;
          &.theader{
            background-color: #f1f1f1;
            color: #253d98;
          }
        }
      }
    }

  .header_content{
    .button{
      &.download{
        background-color: white;
        color: #253d98;
        border: 1px solid #253d98;
      }

      .download_size{
        color: grey;
      }
    }

    border-bottom: 1px solid  #253d98;

  }

  .button{
    &.open-notes{
      background-color: white;
      color: #253d98;
    }
    &.open-unread-notes{
      background-color: white;
      font-weight: bold;
      color: green;
      text-decoration: underline blue;
      padding-left: 1em;
    }
  }
    .subdescription{
      font-size: 0.75em;
      color: rgb(98,98,98) ;
    }
  .totalorder{
    background-color: rgb(235,236,244);
    h3{
      font-weight: bold;
      margin: 0;
    }
    .subdescription{
      font-size: 1em;
      color: rgb(142,143,145);
    }
  }

  .media{
    text-align: center;
  }

  .news{
    border: 2px solid gray;
    &.greennews{
      border: 2px solid green;
    }
    .news-header{
      padding: 1.25rem;
      text-align: left;
      font-size: 1.5em;
      padding-bottom: 0.5rem;
      div{

        border-bottom: 1px solid #d6d5d5;
        padding-bottom: 1rem;
      }

    }
    .news-subheader{
      padding: 0.5rem 1.25rem 0.5rem 1.25rem;
      text-align: left;
    }
    .locationsepator{
      width: 65px;
      margin: .25rem auto .25rem 1.25rem;
    }
    .news-body{
      padding: 0.25rem 1.25rem 1.25rem 1.25rem;
    }
    .comments{
      color: grey;
      &.border {
        border-right: 1px solid rgba(10, 10, 10, 0.25);
      }
    }

    .new{
      font-weight: bold;
      color: green;
      text-decoration: underline blue;
      padding-left: 1em ;
    }

  }


  &.is-act-index {
    .dashboard-entry {
      margin-bottom: rem-calc(10);
    }
  }

  &.is-act-orders {
    .cart {
      margin: 0;
      padding-bottom: rem-calc(20);

      label,
      .cart-label,
      .cart-header {
        font-weight: bold;
      }

      .cart-header {
        border-top: 1px solid #D5D5D5;
        padding: rem-calc(20) rem-calc(20) 0;
        margin-bottom: rem-calc(30);
      }

      .cart-list {
        list-style: none;
        margin: 0;
        border-top: none;
        padding-top: 0;

        .cart-item {
          list-style: none;
          margin-bottom: rem-calc(20);
          padding: rem-calc(20);
          border: 1px solid #ccc;

          .quantity {
            text-align: center;
          }
          .comment{
            color: #ccc;
            font-size: 0.95em;
          }
          .place{
            color: #ccc;
          }

          .kc{
            font-weight:  bold;
          }

          .dph{
            color: #ccc;
            font-size: 0.9em;
          }
          .polozek{
            font-size: 0.8em;
          }
          .status{
            font-size: 0.75em;
          }
          .green{
            background-color: lightgreen;
            color: green;
          }
          .button {
            margin: 0;
          }

          .certificate {
            .noborder {
              border: none;
            }

            .certText {
              color: #12a01c;
            }

            .border-top{

              .button {
                padding: 0;
              }
            }
          }
        }

        .piece-units {
          margin-bottom: rem-calc(20);
          margin-top: rem-calc(20);

          .values {
            padding-right: rem-calc(10);
            border-right: 1px solid #ccc;
          }

          .unit {
            padding-left: rem-calc(10);
          }
        }
      }

      .icon-remove {
        font-size: 1rem;
      }

      .cart-footer {
        .cell:not(.total-price) {
          display: none;
        }
      }
    }
    .order-list {
      border: 1px solid #ccc;

      .list-header {
        font-weight: bold;
        padding: rem-calc(5);

        .cell {
          padding-right: rem-calc(5);
        }
      }

      .list-item {
        padding: rem-calc(5);

        &.odd {
          background: #ccc;
        }
      }
    }
  }

  &.is-act-addresses {
    .address-container {
      position: relative;

      .controls {
        a {
          display: block;
        }

        .primary {
          color: gold;
        }
      }
    }
  }
}

@include breakpoint(large) {
  .is-ctl-account {
    &.is-act-addresses {
      .address-container {
        .controls {
          a {
            display: inline-block;
            padding-left: rem-calc(10);
          }
        }
      }
    }
  }
}


.md-stepper-horizontal {
  display:table;
  width:100%;
  margin:0 auto;
  background-color:#FFFFFF;
  box-shadow: 0 3px 8px -6px rgba(0,0,0,.50);
}
.md-stepper-horizontal .md-step {
  display:table-cell;
  position:relative;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
  background-color:rgba(0,0,0,0.04);
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color:#757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display:none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width:30px;
  height:30px;
  margin:0 auto;
  background-color:#999999;
  border-radius: 50%;
  text-align: center;
  line-height:30px;
  font-size: 16px;
  font-weight: 600;
  color:#FFFFFF;
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
  background-color:#00AE4D;
}
.md-stepper-horizontal.orange .md-step.active .md-step-circle {
  background-color:#F96302;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
  background-color: rgb(33,150,243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
  font-family: 'icomoon' !important;
  font-weight:100;
  content: "\E900";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
  display:none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
  font-family:'FontAwesome';
  font-weight:100;
  content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top:16px;
  min-width: 45px;
  font-size:16px;
  font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color:rgba(0,0,0,.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 600;
  color:rgba(0,0,0,.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
  font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-optional {
  font-size:12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
  color:rgba(0,0,0,.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position:absolute;
  top:36px;
  height:1px;
  border-top:1px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right:0;
  left:50%;
  margin-left:20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left:0;
  right:50%;
  margin-right:20px;
}