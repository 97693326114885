$link-border-color: #c8cee5;

.breadcrumbs {
  margin: 0;

  a {
    border-bottom: 1px solid $link-border-color;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid $primary-color;
    }
  }

  li {
    text-transform: none;
  }
}

@include breakpoint(medium) {
  .breadcrumbs-container {
    margin-bottom: rem-calc(30);
  }
}