.group-listing {
  .group-container {
    margin-bottom: rem-calc(20);
    background: #eee;

    .image {
      position: relative;
      margin: 0 auto rem-calc(20);
      text-align: center;
      overflow: hidden;
      min-height: 2rem;

      img {
        object-fit: cover;
        width: 100%;
        height: auto;
        //transform-origin: 0 0;
        transition: transform .25s, visibility .25s ease-in;

        &:hover {
          transform: scale(1.2);
        }
      }

      .sub-title {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        color: $white;
      }
    }

    .button {
      margin: 0;
    }
  }
}
