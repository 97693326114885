$background: #253D98;
$color: #FFF200;
$border: #5164ad;

.main-footer {
  background: $background;
  color: $white;

  ul {
    margin: 0;
  }

  .footer-top {
    padding: rem-calc(40 0);

    .grid-x {
      align-items: center;
    }

    .mobile {
      border-left: 1px solid $border;
      padding-left: rem-calc(45);
    }

    .brand-contact {
      padding-right: rem-calc(45);
    }

    .cell {
      @include word-wrap;
    }
  }

  a {
    color: $white;
  }

  .footer-heading {
    font-weight: 600;
    margin-bottom: rem-calc(15);
  }

  .brand {
    margin-bottom: rem-calc(60);
  }

  .footer-middle {
    padding: rem-calc(30 0);
    border-bottom: 1px solid $border;

    img {
      display: block;

      &.logo {
        width: rem-calc(65);
        height: auto;
      }
    }
  }

  .footer-bottom {
    padding: rem-calc(35 0);
  }

  .copyright {
    text-align: center;
    margin-bottom: rem-calc(30);
  }

  .design-development {
    text-align: center;

    .development,
    .design {
      padding: rem-calc(10 20);
    }

    .design {
      border-right: 1px solid $border;
    }
  }
}

@include breakpoint(medium) {
  .main-footer {
    .footer-heading {
      margin-bottom: rem-calc(30);
    }

    .footer-top {
      border-bottom: 1px solid $border;

      .grid-x {
        align-items: unset;
      }
    }

    .copyright {
      text-align: left;
      margin-bottom: 0;
    }

    .design-development {
      text-align: right;
    }
  }
}