.is-ctl-checkout,
.is-ctl-cart {
  .grid-container {
    .cart {
      margin: 0;
      padding-bottom: rem-calc(20);

      label,
      .cart-label,
      .cart-header {
        font-weight: bold;
      }

      .cart-header {
        border-top: 1px solid #D5D5D5;
        padding: rem-calc(20) rem-calc(20) 0;
        margin-bottom: rem-calc(30);
      }

      .cart-list {
        list-style: none;
        margin: 0;
        border-top: none;
        padding-top: 0;

        .cart-item {
          list-style: none;
          margin-bottom: rem-calc(20);
          padding: rem-calc(20);
          border: 1px solid #D5D5D5;
          
          .quantity {
            text-align: center;
          }

          .button {
            margin: 0;
          }

          .certificate {
              .noborder {
                border: none;
              }
              
              .certText {
                  color: #12a01c;
              }
              
              .border-top{

                  .button {
                      padding: 0;
                  } 
              }
          }
        }

        .piece-units {
          margin-bottom: rem-calc(20);
          margin-top: rem-calc(20);

          .values {
            padding-right: rem-calc(10);
            border-right: 1px solid #ccc;
          }

          .unit {
            padding-left: rem-calc(10);
          }
        }
      }

      .icon-remove {
        font-size: 1rem;
      }

      .cart-footer {
        .cell:not(.total-price) {
          display: none;
        }
      }
    }
      
    .paymentType {
        border: 1px solid #d5d5d5;
        margin-bottom: 1.25rem;
        padding: 1.25rem;

        .type {
            display: block;
            position: relative;
            text-align: center;

            .offer, .inquiry {
                display: inline-block;
                border: 3px solid #b2b0d2;
                text-align: left;
                
                .actionArea {
                    //display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    //text-align: center;
                    
                    input {
                        vertical-align: middle;
                        margin: 0;
                        padding: 0;
                    }
                }
                
                .radio-button {
                    * {
                        box-sizing: border-box;
                    }

                    //display: inline-block;

                    > input {
                        display: none;
                    }
                    
                    > label {
                        vertical-align: top;
                        //font-size: 18px;
                        display: block;
                    }
                    > [type="radio"]+label {
                        &:before {
                            color: #777;
                            content: '';
                            display: inline-block;
                            min-height: 20px;
                            height: 20px;
                            width: 20px;
                            border: 2px solid #777;
                            border-radius: 100%;
                            margin: 0 25px;
                            font-size: 15px;
                            vertical-align: middle;
                            text-align: center;
                            transition: all 0.3s linear;
                            content: '';
                        }
                    }
                    > [type="radio"] {
                        &:not(:checked)+label {
                            &:before {
                                background-color: transparent;
                            }
                            /*
                            &:hover {
                                &:before {
                                    background: -moz-radial-gradient(center, ellipse cover, rgba(124, 188, 10, 0.7) 0%, rgba(157, 213, 58, 0) 100%);
                                    background: -webkit-radial-gradient(center, ellipse cover, rgba(124, 188, 10, 0.7) 0%, rgba(157, 213, 58, 0) 100%);
                                    background: radial-gradient(ellipse at center, rgba(124, 188, 10, 0.7) 0%, rgba(157, 213, 58, 0) 100%);
                                    filter: "progid: DXImageTransform.Microsoft.gradient(startColorstr='#b37cbc0a', endColorstr='#009dd53a', GradientType=1)";
                                }
                            }
                            */
                        }
                        &:checked+label {
                            &:before {
                                background: -moz-radial-gradient(center, ellipse cover, #000000 0%,#000000 50%, #ffffff 53%, #ffffff 100%);
                                background: -webkit-radial-gradient(center, ellipse cover, #000000 0%,#000000 50%, #ffffff 53%, #ffffff 100%);
                                background: radial-gradient(ellipse at center, #000000 0%,#000000 50%, #ffffff 53%, #ffffff 100%);
                                filter: "progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#ffffff', GradientType=1)";
                            }
                        }
                    }

                    div {
                        border-left: 1.5px solid #b2b0d2;
                        display: inline-block;
                        padding: 10px 15px;
                        width: calc(100% - 74px);
                        vertical-align: middle;
                        
                        .icon {
                            display: block;
                            font-size: 35px;
                        }
                        
                        .green {
                            color: #12a01c;
                        }
                    }

                }

                .textArea {
                    display: inline-block;
                    width: calc(80% - 1px);
                    border-left: 1px solid grey;
                    vertical-align: middle;
                }
            }
        }
    }
  }
}


.cart-overlay {
  display: none;
  position: fixed;
  background: $white;
  border: 1px solid #d6d5d5;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 25;
  height: calc(100% - #{rem-calc(80)});
  overflow-y: auto;

  text-align: left;
  color: $black;
  margin-top: rem-calc(5);

  .cart-title {
    font-weight: 600;
  }

  .cart-content {
    text-align: center;
  }

  a {
    color: #253D98;
  }

  p {
    padding: 1rem 1.875rem;
    margin: 0;
    border-bottom: 1px solid #d6d5d5;
    text-align: left;
  }

  .button {
    color: $white;
  }

  &.is-opened {
    display: block;
  }

  .cart-footer,
  .cart-header {
    padding: 1rem 1.875rem;
  }

  .price,
  .sub-total {
    font-weight: 600;
  }

  .cart-control {
    border-top: 1px solid #aeacd0;;
    padding: 1rem 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cart-list {
    margin: 0;
    overflow-y: auto;
  }

  .cart-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 1rem 1.875rem;
    border-bottom: 1px solid #d6d5d5;
    text-align: left;
  }

  .item-description {
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .item-image {
    padding-right: 1rem;
    flex-shrink: 0;

    img {
      width: 60px;
      height: auto;
    }
  }

  .item-content {
    flex-grow: 1;
    padding-right: 1rem;
  }

  .ring-loader {

  }

  .icon-remove {
    font-size: 1rem;
    cursor: pointer;
  }
}

.cart-button {
  &,
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    background: #10C11C;
  }

  &:focus,
  &:hover,
  &.success {
    background: darken(#10C11C, 5%);
  }
}


@include breakpoint(medium) {
  .cart-overlay {
    p,
    .cart-header,
    .cart-footer,
    .cart-control,
    .cart-item {
      padding: 1rem 1.25rem;
    }
  }
}

@include breakpoint(large) {
  .is-ctl-checkout,
  .is-ctl-cart {
    .grid-container {
      .cart {
        .cart-list {
          .piece-units {
            margin-top: 0;
          }
        }
      }
    }
  }

  .cart-overlay {
    position: absolute;
    left: unset;
    height: auto;
    width: rem-calc(400);
    top: 0;
    margin: 0;
    border: 2px solid #aeacd0;

    .cart-header {
      background: #f4f3f3;
      padding: rem-calc(30 20);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #aeacd0;;

      .icon-remove {
        font-weight: bold;
      }
    }

    .cart-control {
      padding: rem-calc(20);
    }

    p {
      padding: rem-calc(30 20);
    }

    .cart-footer {
      padding: rem-calc(15 20);
    }

    .cart-list {
      max-height: rem-calc(350);
    }

    .button {
      margin: 0;
    }

    &.is-opened {
      display: block;
    }

    .item-image {
      img {
        width: 45px;
        height: auto;
      }
    }
  }
}
