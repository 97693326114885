.is-ctl-shopping-list.is-act-detail {
  .shopping-list-header {
    border-top: 1px solid #aeacd0;
    padding: rem-calc(20) rem-calc(20) 0;
    margin-bottom: rem-calc(30);
  }

  .shopping-list {
    border-top: none;
    padding-top: 0;

    .shopping-list-item {
      padding: rem-calc(20);
      border: 1px solid #D5D5D5;
    }
  }


  .icon-remove {
    font-size: 1rem;
  }
}
