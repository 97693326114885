$border-color: #d6d5d5;

.is-ctl-account-settings.is-act-edit-company,
.is-ctl-account-settings.is-act-user-profile,
.is-ctl-registration.is-act-register {
  .container {
    margin-bottom: rem-calc(20);
    border: 1px solid $border-color;
    padding: rem-calc(0 25);
    transition: background-color 0.25s ease-out, color 0.25s ease-out;

    textarea {
      min-height: rem-calc(200);
    }

    label {
      font-size: rem-calc(16);

      &:not(.is-invalid-label) {
        color: #626262;
      }
    }

    .button {
      margin-bottom: 0;
      margin-top: rem-calc(10);

      &.ares {
        font-size: rem-calc(16);
        position: relative;

        &.loader-active {
          padding-right: rem-calc(40);
        }
      }
    }

    .container-content {
      padding: rem-calc(25 0);

      &.fit-to-top {
        padding-top: 0;
      }
    }

    .container-heading {
      font-size: rem-calc(20);
      padding: rem-calc(25 0);
      border-bottom: 1px solid $border-color;
      position: relative;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;

      button {
        margin: 0;
        pointer-events: none;
        padding: rem-calc(10);
        position: absolute;
        right: 0;

        .icon:before {
          @include icomoon-icon;
          @include icon-angle-up;
        }
      }

      &.collapse-able {
        cursor: pointer;
      }

      &.is-collapsed {
        margin: 0;
        border: none;
        background: #F4F3F3;
        margin-left: rem-calc(-25);
        margin-right: rem-calc(-25);
        padding: rem-calc(25);

        button {
          background: $white;
          border: 1px solid $black;
          right: rem-calc(25);

          .icon:before {
            @include icon-angle-down;
            color: $black;
          }
        }
      }

      label {
        color: $black;
        font-size: rem-calc(20);
        vertical-align: middle;

        &:before {
          vertical-align: baseline;
          top: rem-calc(3);
          overflow: hidden;
        }
      }
    }
  }
}