.input-group.amount {
  border: 1px solid #aeacd0;
  margin: 0;
  max-width: rem-calc(128);

  button {
    background: #EBECF4;
    color: $black;

    &:hover {
      background: darken(#EBECF4, 2%);
    }

    &:disabled:hover {
      color: $black;
      background: #EBECF4;
    }
  }
}
