.buybox-mobile {
  position: fixed;
  z-index: 9;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  left: 0;
  background: white;
  border-top: 1px solid black;
  transition: transform 1s;

  &.opened {
    transform: translateY(0)!important;
    min-height: 100vh;
    border-top: none;
  }

  .show-details {
    background: #EBECF4;
    min-height: 100%;

    .button {
      height: 100%;
    }
  }
  
  .price {
    text-align: right;
  }

  .button {
    margin: 0;
  }
}

.buybox {
  border: 2px solid #d6d5d5;

  .buybox-header {
    background: $primary-color;
    padding: rem-calc(20);
    text-align: right;
    border-bottom: 1px solid #d6d5d5;

    .price-total {
      color: $black;
      font-weight: bold;
      font-size: rem-calc(20);
    }

    .price-vat {
      color: $white;
    }
  }

  .buybox-body {
    background: #f4f3f3;
    padding: rem-calc(20);

    .row {
      margin-bottom: rem-calc(5);
    }
  }

  .buybox-footer {
    padding: rem-calc(10) rem-calc(20);
    border-top: 1px solid #d6d5d5;
  }
}

@include breakpoint(large) {

}