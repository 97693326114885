.is-ctl-product.is-act-detail {
  .headline {
    display: block;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .images {
    img {
      border: 1px solid #ccc;
      //@todo
      width: 100%;
    }

    .thumbnail-container {
      margin-top: rem-calc(20);
      margin-left: rem-calc(-10);
      margin-right: rem-calc(-10);

      .image {
        width: calc(100% / 3);
        float: left;
        padding-right: rem-calc(10);
        padding-left: rem-calc(10);
      }
    }
  }

  .labels {
    margin-bottom: rem-calc(15);
  }

  .description {
    margin-bottom: rem-calc(15);
  }

  .amount-configuration {
    .unit-value {
      padding: 0.5rem 0;
    }
  }

  .product-information {
    margin-bottom: rem-calc(10);

    .info-box {
      background: #ebecf4;
      border: 2px solid #ebecf4;
      padding: rem-calc(15);

      .values {
        font-weight: bold;
      }

      &.unit-prices {
        background: $white;
        border: 2px solid #aeacd0;
      }

      .grid-x {
        margin-bottom: rem-calc(10);
      }
    }
  }

  .branches {
    margin-bottom: rem-calc(10);

    .current-stock,
    .branches-stock,
    .current-branch {
      padding: rem-calc(5) rem-calc(10);

      &.in-stock {
        color: #12a01c;
      }
    }

    .current-branch {
      border-top: 1px solid #d6d5d5;
      border-bottom: 1px solid #d6d5d5;

      .distance {
        color: #22a62b;
      }

      a {
        &.home {
          &:before {
            @extend %icomoon-icon;
            content: "\e914";
            padding-right: rem-calc(5);
          }
        }
      }

      button {
        cursor: pointer;
        float: right;
        border: 1px solid #ccc;
        padding: 0 rem-calc(5);
      }
    }

    .branches-stock {
      margin-top: rem-calc(25);
      margin-bottom: rem-calc(25);
      color: red;

      span {
        font-weight: bold;
      }

      &.in-stock {
        color: #22a62b;
      }
    }
    
    .home-branch-stock {
        margin: unset;
        border-top: 1px solid #d6d5d5;
        border-bottom: 1px solid #d6d5d5;
        color: black;
        
        .amount {
            text-align: right;
            color: green;
        }
        
        .days {
            text-align: right;
            color: #626262;
        }
    }
  }

  .other-variants {
    margin-top: rem-calc(20);

    .actual-variant{
      background: #253d98;
    }

  }

  .product-detail-content {
    position: relative;

    .price {
      font-size: rem-calc(30);
      font-weight: bold;
    }
  }

  .tabs-container {
    margin-top: rem-calc(20);

    .tabs-panel {
      &.description {
        .information {
          margin-top: rem-calc(20);
        }
      }
    }
  }

  .notifications {
    margin-top: rem-calc(20);
  }

  @include breakpoint(medium) {
    .labels {
      margin: 0;
    }

    .branches {
      .cell + .cell {
        border-left: 0.5px solid #d6d5d5;
      }

      .branches-stock {
        //border-top: 1px solid #d6d5d5;
        //border-bottom: 1px solid #d6d5d5;
        margin: 0;
      }
    }
  }
}
