.off-canvas {
  .close-button {
    z-index: 2;
  }

  &.navigation {
    padding: rem-calc(20) 0;

    .navigation-list {
      .navigation-entry {
        .navigation-link {
          background: transparent;
        }
      }
    }

    .drilldown {
      a {
        background: $light-gray;
      }

      .my-account {
        border-top: 1px solid #ccc;
      }

      .is-drilldown-submenu {
        margin: 0;
        background: $light-gray;
      }
    }
  }

  &.cart-overlay {
    width: 100%;
    transform: translateX(100%);

    &.is-open {
      transform: translate(0, 0);
    }

    @include breakpoint(medium) {
      width: 400px;
      transform: translateX(400px);
    }
  }
}