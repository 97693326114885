@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/iconfont/icomoon.eot?7vulnx');
  src: url('../../fonts/iconfont/icomoon.eot?7vulnx#iefix') format('embedded-opentype'),
  url('../../fonts/iconfont/icomoon.ttf?7vulnx') format('truetype'),
  url('../../fonts/iconfont/icomoon.woff?7vulnx') format('woff'),
  url('../../fonts/iconfont/icomoon.svg?7vulnx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icomoon-icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icomoon-icon {
  @include icomoon-icon;
}

[class^="icon-"], [class*=" icon-"] {
  @extend %icomoon-icon;
}

@mixin icon-check {
  content: "\e900";
}

@mixin icon-angle-down {
  content: "\e901";
}

@mixin icon-e-remove-1 {
  content: "\e902";
}

@mixin icon-remove-alt {
  content: "\e903";
}

@mixin icon-apek {
  content: "\e904";
}

@mixin icon-ekokom {
  content: "\e905";
}

@mixin icon-gdpr {
  content: "\e906";
}

@mixin icon-register {
  content: "\e907";
}

@mixin icon-angle-up {
  content: "\e908";
}

@mixin icon-cart {
  content: "\e909";
}

@mixin icon-cart-add {
  content: "\e90a";
}

@mixin icon-cart-remove {
  content: "\e90b";
}

@mixin icon-search {
  content: "\e93b";
}

@mixin icon-angle {
  content: "\e92a";
}

@mixin icon-bars {
  content: "\e92b";
}

@mixin icon-bolt {
  content: "\e92c";
}

@mixin icon-shopping-cart {
  content: "\e92d";
}

@mixin icon-delivery {
  content: "\e92e";
}

@mixin icon-energy {
  content: "\e92f";
}

@mixin icon-filter {
  content: "\e930";
}

@mixin icon-home {
  content: "\e931";
}

@mixin icon-clipboard-list {
  content: "\e932";
}

@mixin icon-clipboard-list-checked {
  content: "\e933";
}

@mixin icon-misc {
  content: "\e934";
}

@mixin icon-plus {
  content: "\e935";
}

@mixin icon-puzzle {
  content: "\e936";
}

@mixin icon-remove {
  content: "\e937";
}

@mixin icon-settings {
  content: "\e938";
}

@mixin icon-shape-adjust {
  content: "\e939";
}

@mixin icon-user {
  content: "\e93a";
}

@mixin icon-edit {
  content: "\e91b";
}

@mixin icon-envelope {
  content: "\e918";
}

@mixin icon-list {
  content: "\e922";
}

@mixin icon-question-circle {
  content: "\e915";
}

@mixin icon-share-alt {
  content: "\e927";
}

@mixin icon-star {
  content: "\e91d";
}

@mixin icon-star-filled {
  content: "\e91e";
}

@mixin icon-table {
  content: "\e924";
}

@mixin icon-table-alt {
  content: "\e929";
}

@mixin icon-th {
  content: "\e921";
}

.icon-check:before {
  @include icon-check;
}

.icon-angle-down:before {
  @include icon-angle-down;
}

.icon-e-remove-1:before {
  @include icon-e-remove-1;
}

.icon-remove-alt:before {
  @include icon-remove-alt;
}

.icon-apek:before {
  @include icon-apek;
}

.icon-ekokom:before {
  @include icon-ekokom;
}

.icon-gdpr:before {
  @include icon-gdpr;
}

.icon-register:before {
  @include icon-register;
}

.icon-angle-up:before {
  @include icon-angle-up;
}

.icon-cart:before {
  @include icon-cart;
}

.icon-cart-add:before {
  @include icon-cart-add;
}

.icon-cart-remove:before {
  @include icon-cart-remove;
}

.icon-search:before {
  @include icon-search;
}

.icon-angle:before {
  @include icon-angle;
}

.icon-bars:before {
  @include icon-bars;
}

.icon-bolt:before {
  @include icon-bolt;
}

.icon-shopping-cart:before {
  @include icon-shopping-cart;
}

.icon-delivery:before {
  @include icon-delivery;
}

.icon-energy:before {
  @include icon-energy;
}

.icon-filter:before {
  @include icon-filter;
}

.icon-home:before {
  @include icon-home;
}

.icon-clipboard-list:before {
  @include icon-clipboard-list;
}

.icon-clipboard-list-checked:before {
  @include icon-clipboard-list-checked;
}

.icon-misc:before {
  @include icon-misc;
}

.icon-plus:before {
  @include icon-plus;
}

.icon-puzzle:before {
  @include icon-puzzle;
}

.icon-remove:before {
  @include icon-remove;
}

.icon-settings:before {
  @include icon-settings;
}

.icon-shape-adjust:before {
  @include icon-shape-adjust;
}

.icon-user:before {
  @include icon-user;
}

.icon-edit:before {
  @include icon-edit;
}

.icon-envelope:before {
  @include icon-envelope;
}

.icon-list:before {
  @include icon-list;
}

.icon-question-circle:before {
  @include icon-question-circle;
}

.icon-share-alt:before {
  @include icon-share-alt;
}

.icon-star:before {
  @include icon-star;
}

.icon-star-filled:before {
  @include icon-star-filled;
}

.icon-table:before {
  @include icon-table;
}

.icon-table-alt:before {
  @include icon-table-alt;
}

.icon-th:before {
  @include icon-th;
}
