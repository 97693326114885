.article-stock.in-stock {
    color: #12a01c;
    cursor: pointer;
}

.reveal.stock-info {
    background: rgba(0, 0, 0, 0.6);
    border: 0px;

    .close-button {
        right: 1.5rem;
        top: 1.5rem;
    }

    .content-stock {
        border: 4px solid #AEACD0;
        background: white;

        h2 {
            padding: 10px;
            background: #F4F3F3;
            border-bottom: 2px solid #AEACD0;
        }

        .ajax-container {
            padding: 20px;
        }

        .stock-modal {
            thead {
                background: none;
                border: none;

                th {
                    padding: 0;

                    span {
                        font-size: 13px;
                        color: #626262;
                    }
                }
            }

            tbody {
                border: 0px;

                &:before{
                    content: "";
                    margin: 5px;
                }

                tr {
                    &:nth-child(even) {
                        background: none;
                    }
                }
            }

            .stock-available {
                text-align: right;
            }

            .stock-enable {
                text-align: right;
            }
        }
    }

    .stock-modal tbody:before{
        content: "";
        margin: 5px;
    }

    .stock-modal tbody td {
        border-top: 1px solid #D6D5D5;
        border-bottom: 1px solid #D6D5D5;
    }
}