.pagination {
  margin-top: rem-calc(20);

  a {
    border: 1px solid #AEACD0;
  }

  li.disabled, a, li.current {
    min-width: rem-calc(42);
    text-align: center;
  }

  $padding-left: get-side($pagination-item-padding, 'left');
  $padding-top: get-side($pagination-item-padding, 'top');
  $padding-right: get-side($pagination-item-padding, 'right');
  $padding-bottom: get-side($pagination-item-padding, 'bottom');
  .current {
    border: 2px solid #AEACD0;
    padding: calc(#{$padding-top} - 1px) calc(#{$padding-right} - 1px) calc(#{$padding-bottom} - 1px) calc(#{$padding-left} - 1px);
  }
}