select:not([multiple]) {
  -webkit-appearance: none;
  appearance: none;
  color: $black;
  border: 2px solid #AEACD0;
  line-height: 1;
  outline: 0;
  padding: 0.65rem 2.5rem 0.55rem 0.75rem;
  background-color: $white;
  background-image: linear-gradient($black, $black),
  linear-gradient(-135deg, transparent 50%, #EBECF4 50%),
  linear-gradient(-225deg, transparent 50%, #EBECF4 50%),
  linear-gradient(#EBECF4 42%, $black 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 0 100%, 24px 32px, 25px 30px, 24px 100%;
  background-position: right 20px center, right bottom, right bottom, right bottom;
  background-origin: unset;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="remail"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  border: 2px solid #AEACD0;
  padding: 0.65rem 2.5rem 0.55rem 0.75rem;

  &:disabled {
    background: #F4F3F3;
    border-color: #d6d5d5;
  }
}

input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
  margin: 0;

  & + label {
    position: relative;
    margin-left: 0;

    &::before {
      content: " ";
      display: inline-block;
      border: 2px solid #AEACD0;
      width: rem-calc(20);
      height: rem-calc(20);
      background: $white;
      vertical-align: top;
      margin-right: rem-calc(8);
      @extend %icomoon-icon;
      font-size: rem-calc(10);
      padding: rem-calc(3);
      position: relative;
      top: rem-calc(2);
    }

    &.is-invalid-label {
      &::before {
        border: 2px solid $input-error-color;
        color: $input-error-color;
      }
    }
  }

  &:checked {
    & + label {
      &::before {
        content: "\e900";
      }
    }
  }
}

input[type=number]{
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
}

label {
  &.required {
    &:after {
      content: ' *';
    }
  }
}

.button {
  &.green {
    background: #10C11C;
    color: $white;

    &.bordered {
      border: 1px solid #12a01c;
    }
  }
}