$alert-callout-border-left-width: rem-calc(5);
$alert-callout-border-padding: 1.3rem;
$alert-callout-border-radius: 0.6rem;

.alert-callout-border {
  width: 100%;
  padding: $alert-callout-border-padding;
  border-left-width: $alert-callout-border-left-width;
  border-left-color: $medium-gray;

  strong {
    color: $body-font-color;
  }

  .close-button {
    @include vertical-center;
  }

  &.radius {
    border-radius: $alert-callout-border-radius;
  }

  &.primary {
    border-left-color: $primary-color;

    strong {
      color: $primary-color;
    }
  }

  &.secondary {
    border-left-color: $secondary-color;

    strong {
      color: $secondary-color;
    }
  }

  &.alert {
    border-left-color: $alert-color;

    strong {
      color: $alert-color;
    }
  }

  &.success {
    border-left-color: $success-color;

    strong {
      color: $success-color;
    }
  }

  &.warning {
    border-left-color: $warning-color;

    strong {
      color: $warning-color;
    }
  }
}
