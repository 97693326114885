.off-canvas {
  &.navigation {
    width: 100%;
    transform: translateX(100%);

    &.is-open {
      transform: translate(0, 0);
    }

    @include breakpoint(medium) {
      width: rem-calc(400);
      transform: translateX(rem-calc(400));
    }
  }
}