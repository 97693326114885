body {
  min-width: rem-calc(440);
  min-height: 100vh;
  background: $white;

  &.cart-container-opened,
  &.search-container-opened,
  &.disable-scroll {
    overflow-y: hidden;
  }

  &.cart-container-opened {
    .main-header {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 24;
    }
  }

  #main-content {
    position: relative;
    &> .grid-container {
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);
    }
  }

  #main-content-overlay {
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    transition: visibility 0.1s linear, opacity 0.1s linear;
    position: absolute;
    background: rgba(0,0,0,0.7);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &.mega-menu-opened {
    #main-content-overlay {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }
}

@include breakpoint(medium) {
  body {
    .off-canvas-content {
      #main-content > .grid-container {
        position: relative;
        z-index: 1;
        padding-top: rem-calc(35);
        padding-bottom: rem-calc(20);
      }
    }
  }
}

@include breakpoint(large) {
  body {
    &.cart-container-opened,
    &.search-container-opened {
      overflow-y: auto;
    }

    &.cart-container-opened {
      .main-header {
        position: static;
      }
    }
  }
}